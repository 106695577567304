<template>
  <div>
    <div class="login-container" v-if="!loading">
      <form class="form-signin" @submit.prevent="onLogin">
        <h1 class="h3 mb-3 font-weight-normal">Identifique-se</h1>
        <label for="inputPassword" class="sr-only">Código</label>
        <input
          type="password"
          class="form-control"
          placeholder="Código"
          v-model="form.code"
          required
        />
        <button class="btn btn-lg btn-primary btn-block" type="submit">
          Entrar
        </button>
        <p class="mt-5 mb-3 text-center text-muted">&copy; Viacom LIG16</p>
      </form>
    </div>
    <div v-else>
      <p class="text-center p-2">Entrando...</p>
    </div>
  </div>
</template>
<script>
import "./_login.scss";
import { storage } from "../../libs";
export default {
  name: "login",
  data: () => ({
    loading: false,
    form: {
      code: "",
    },
  }),
  methods: {
    async onLogin() {
      this.loading = true;
      try {
        let { data } = await this.$http.post("/auth/login", this.form);
        storage.setToken(data.token);
        this.$router.push({
          path: "/",
        });
      } catch (error) {
        alert("Falha ao entrar: " + (error.message || "Desconhecido"));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
